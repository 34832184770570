<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
            <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'user-dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import LoginCard from '@/components/LoginCard.vue';

export default {
    components: {
        LoginCard,
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.redirectAuthenticatedUser();
            }
        },
    },
    methods: {
        init() {
            if (this.isAuthenticated) {
                this.redirectAuthenticatedUser();
            }
        },
        redirectAuthenticatedUser() {
            // TODO: we need to check query parameters, if there's an account id redirect to the dashboard for that account, otherwise if there's only one account, go to that one, or if there's more than one show the account selection , and if the user doens't have any accounts then show a message that they need to contact the administrator to be added to an account
            this.$router.replace({ name: 'user-dashboard' });
        },
    },
    mounted() {
        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
